<template>
  <div class="auth-wrapper">
    <div class="auth-inner">
      <form class="form" @submit.prevent="login">
        <h3 class="title">Tizimga kirish</h3>
        <div class="form-group">
          <label>Telefon raqamingiz</label>
          <input
            required
            autofocus
            type="text"
            placeholder="+998 __ ___ __ __"
            class="form-control mask-tel"
            id="phone"
          />
        </div>
        <div class="form-group">
          <label>Parolingiz</label>
          <div class="view-password d-flex">
            <input
              v-model="form.password"
              :type="inputType"
              minLength="8"
              class="form-control"
            />
            <button
              @click="toggleVisible"
              tabIndex="-1"
              type="button"
              class="btn btn-eye ml-2 btn-orange"
            >
              <i class="fas fa-eye"></i>
            </button>
          </div>
        </div>
        <button class="btn btn-orange btn-block">Kirish</button>
        <p class="forgot-password d-flex justify-content-between">
          <router-link :to="{ name: 'recover' }" class="link">
            Accountni tiklash
          </router-link>
          <router-link :to="{ name: 'register' }" class="link">
            Ro'yxatdan o'tish
          </router-link>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data: function () {
    return {
      visible: false,
      form: {
        phone: "",
        password: "",
      },
    };
  },
  computed: {
    inputType() {
      return this.visible ? "text" : "password";
    },
  },
  methods: {
    async login() {
      // eslint-disable-next-line no-undef
      this.form.phone = $("#phone").val();

      if (await this.$store.dispatch("user/login", this.form)) {
        this.$toast.success("Siz tizimdasiz.");
        await this.$router.push({ name: "home" });
      }
    },
    toggleVisible: function () {
      this.visible = !this.visible;
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    if ($(".mask-tel").length) {
      // eslint-disable-next-line no-undef
      $(".mask-tel").inputmask({ mask: "+\\9\\9\\8 99 999 99 99" });
    }
  },
};
</script>

<style scoped></style>
